<template>
    <div class="contract-change-page">
        <div class="contract-change-block" v-if="false">
            <div class="header-bread">
                <a-row>
                    <a-col :span="3">
                        变更合同
                    </a-col>
                    <a-col :span="18">
                    </a-col>
                    <a-col :span="3" style="text-align: right">
                        <a-button type="primary">提交</a-button>
                        <a-button style="margin-left: 10px">返回</a-button>
                    </a-col>
                </a-row>
            </div>

            <div style="height: 1px ;background-color: lightgrey;"></div>

            <div class="student-info-content content-item-block">
                <div class="vertical-line">学员信息</div>
                <a-row class="student-info-row" style="">
                    <div>宝宝姓名 汪乐</div>
                    <div> 昵称 旺旺</div>
                    <div> 出生日期 2020-10-24</div>
                    <div>性别 女</div>
                </a-row>
            </div>

            <div class="change-contract-form">
                <a-form-model :model="form" :label-col="labelCol">
                    <div class="plain-change-form">
                        <a-row class="vertical-line">变更信息</a-row>
                        <a-row>


                            <div class="course-plain-row ">
                                <a-form-model v-model="changeContractForm" class="">
                                    <a-form-model-item label="原合同">
                                        <a-cascader
                                            v-model="changeContractForm.feeStandard"
                                            :options="feeOptions"
                                            :default-value="['1', '11', '111']"
                                            style="width:500px"
                                            :disabled="true"
                                        >
                                        </a-cascader>
                                    </a-form-model-item>
                                    <a-form-model-item label="新收费标准">
                                        <a-cascader
                                            v-model="changeContractForm.feeStandard"
                                            :options="feeOptions"
                                            :default-value="['1', '11', '111']"
                                            style="width:500px"
                                        >
                                        </a-cascader>
                                    </a-form-model-item>
                                    <a-form-model-item label="优惠方案(主)">
                                        <a-select default-value="lucy" v-model="changeContractForm.mainActive"
                                                  style="width:500px">
                                            <a-select-option value="jack">
                                                基础能力满96减100
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                    <a-form-model-item label="优惠方案(从)">
                                        <span style="color: #108ee9">+新增优惠方案（从）</span>
                                    </a-form-model-item>
                                    <br>
                                </a-form-model>
                            </div>
                            <a-divider></a-divider>
                            <div style="display: flex;justify-content: right;padding-right: 200px">
                                <div>
                                    <p>总计: <span style="font-weight:bold;font-size: 22px ">  19900 </span> 元</p>
                                </div>
                            </div>
                        </a-row>
                    </div>


                    <div class="extends-change-form">
                        <a-row class="vertical-line">其他信息</a-row>
                        <a-row>
                            <a-col :span="6">
                                <a-form-model-item label="签单人" style="width: 100%" class="extends-contract-item">
                                    <a-select default-value="lucy" v-model="changeContractForm.signUser"
                                              style="width: 100%">
                                        <a-select-option value="jack">
                                            王丽
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="1"></a-col>
                            <a-col :span="6">
                                <a-form-model-item label="审批人" style="width: 100%" class="extends-contract-item">
                                    <a-select default-value="lucy" v-model="changeContractForm.approveUser"
                                              style="width: 100%">
                                        <a-select-option value="jack">
                                            张三
                                        </a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="6"></a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="13">
                                <a-form-model-item label='备注' style="width: 100%" class="extends-contract-item">
                                    <a-textarea v-model="changeContractForm.remark" :row="5" placeholder="请输入备注"/>
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </div>

                </a-form-model>
            </div>
        </div>


        <div class="contract-pay-canvas">
            <div class="header-bread">
                <a-row>
                    <a-col :span="3">
                        交定金
                    </a-col>
                    <a-col :span="18">
                    </a-col>
                    <a-col :span="3" style="text-align: right">
                        <a-button type="primary">提交</a-button>
                        <a-button style="margin-left: 10px">返回</a-button>
                    </a-col>
                </a-row>
            </div>
            <div style="height: 1px ;background-color: lightgrey;"></div>
            <div class="contract-title-row content-item-block">
                <div class="title-text">七田真 深圳百花 定金凭证 <span></span></div>
            </div>

            <div class="page-search-form">
                <a-row>
                    <a-col :span="16">
                        <a-form layout="inline" :form="changePaymentForm">
                            <a-form-item layout="inline" label="交费地点">
                                <a-cascader
                                    v-model="changePaymentForm.center"
                                    :options="centers"
                                    :default-value="['1', '11', '111']"
                                    style="width:180px"
                                >
                                </a-cascader>
                            </a-form-item>
                        </a-form>
                    </a-col>
                    <a-col :span="7" style="text-align: right">凭证号：7984844</a-col>
                    <a-col :span="1"></a-col>
                </a-row>
            </div>


            <div class="contract-ticket-canvas">
                <a-row class="pay-info-row">
                    <a-col :span="1" style="text-align: center"></a-col>
                    <a-col :span="2" style="text-align: left">学员姓名：汪乐</a-col>
                    <a-col :span="6" style="text-align: center"></a-col>
                    <a-col :span="4" style="text-align: center"> 课程名称：基础能力课</a-col>
                    <a-col :span="5" style="text-align: center"></a-col>
                    <a-col :span="3" style="text-align: center">凭证号：7984844</a-col>
                </a-row>
                <a-row class="table-row">
                    <a-col :span="8" style="text-align: center"> 原合同</a-col>
                    <a-col :span="8" style="text-align: center" class="table-center-col">数量</a-col>
                    <a-col :span="8" style="text-align: center">学费</a-col>
                </a-row>

                <a-row class="table-row">
                    <a-col :span="8" style="text-align: center"> 收费标准名称</a-col>
                    <a-col :span="8" style="text-align: center" class="table-center-col">48课时</a-col>
                    <a-col :span="8" style="text-align: center">1000.00</a-col>
                </a-row>

                <a-row class="table-row">
                    <a-col :span="8" style="text-align: center"> 新收费标准</a-col>
                    <a-col :span="8" style="text-align: center" class="table-center-col">数量</a-col>
                    <a-col :span="8" style="text-align: center">学费</a-col>
                </a-row>


                <a-row class="table-row table-last-row">
                    <a-col :span="8" style="text-align: center"> 收费标准名称</a-col>
                    <a-col :span="8" style="text-align: center" class="table-center-col">96课时</a-col>
                    <a-col :span="8" style="text-align: center">2000.00</a-col>
                </a-row>


                <a-row class="pay-type-row">
                    <a-col :span="2"></a-col>
                    <a-col :span="8" style="text-align: left;">交费方式：
                        <a-select default-value="y" v-model="changePaymentForm.payType" style="width: 150px;">
                            <a-select-option :value="item.value" v-for="(item,index) in paymentOptions"
                                             :key="index">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="14" style="text-align: center;border-left: 1px solid lightgrey">交费金额: &nbsp;&nbsp;&nbsp;&nbsp;
                        <a-input v-model="changePaymentForm.payAmount" placeholder="请输入交费金额"
                                 style="width:150px "/>
                    </a-col>
                </a-row>
                <a-row class="pay-desc-row" style="">
                    <a-col :span="2"></a-col>
                    <a-col :span="8" style="text-align: left">交费说明</a-col>
                    <a-col :span="14" class="pay-remark-row">
                        <a-textarea class="pay-remark-input" v-model="changePaymentForm.remark"
                                    placeholder="请输入交费说明"
                                    :auto-size="{ minRows: 2, maxRows: 2 }"
                        />
                    </a-col>
                </a-row>
            </div>

            <div class="pay-operator-row">
                <a-col :span="4" style="text-align: left">经办人
                    <a-select default-value="y" v-model="changePaymentForm.operator" style="width: 150px;">
                        <a-select-option :value="item.value" v-for="(item,index) in operators"
                                         :key="index">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-col>
                <a-col :span="8" style="text-align: left">出纳
                    <a-select default-value="y" v-model="changePaymentForm.operator" style="width: 150px;">
                        <a-select-option :value="item.value" v-for="(item,index) in operators"
                                         :key="index">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-col>
                <a-col :span="12" style="text-align: right">
                    交费日期
                    <a-date-picker show-time v-model="changePaymentForm.payDate"></a-date-picker>
                </a-col>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    name: 'ContactBargainPay',
    data() {
        return {
            showChangeGuardian: false,
            showChangeAddr: false,
            changeContractForm: {},


            changePaymentForm: {},

            feeOptions: [
                {
                    value: '1',
                    label: '基础能力课时包',
                    children: [
                        {
                            value: '11',
                            label: '按课时收费',
                            children: [
                                {
                                    value: '111',
                                    label: '96课时',
                                },
                            ],
                        },
                    ],
                },
            ],
            operators: [
                {
                    name: '张三',
                }
            ],
            paymentOptions: [
                {
                    value: '1',
                    label: '微信',
                }
            ],
            centers: [
                {
                    value: '1',
                    label: '华南',
                    children: [
                        {
                            value: '11',
                            label: '广东',
                            children: [
                                {
                                    value: '111',
                                    label: '深圳百花',
                                },
                            ],
                        },
                    ],
                },
            ],


        }
    },
    created() {
    },
    mounted() {

    },
    methods: {}
}
</script>
<style scoped lang="less">
.contract-change-page {
    height: 100%;

    .contract-change-block {
        box-sizing: border-box;
        padding-bottom: 120px;
        height: 100%;

        .student-info-content {
            background-color: #fff;
            padding: 40px;

            .student-info-row {
                display: flex;
                justify-content: space-between;
                background-color: lightgray;
                height: 80px;
                line-height: 80px;
                border-radius: 5px;
                margin-top: 20px;
            }
        }


        .change-contract-form {

            .plain-change-form {
                padding: 40px;
                margin-top: 20px;
                background-color: #fff;
                padding-left: 30px;
            }

            .extends-change-form {
                padding: 40px;
                margin-top: 20px;
                background-color: #fff;
                padding-left: 30px;
            }
        }


    }

    .contract-pay-canvas {
        height: 100%;
        background-color: #fff;
        padding-left: 40px;
        padding-right: 100px;

        .contract-title-row {
            text-align: center;
            padding-top: 30px;
            padding-bottom: 30px;
            font-size: 20px;
            font-weight: bold;

        }

        .contract-ticket-canvas {
            margin-top: 20px;
            border: 1px solid lightgrey;

            .pay-info-row {
                line-height: 60px;
                border-bottom: 1px solid lightgrey;
            }

            .table-row {
                height: 28px;
                line-height: 28px;
                border-top: 1px solid lightgrey;

                .table-center-col {
                    border-left: 1px solid lightgrey;
                    border-right: 1px solid lightgrey;
                }
            }

            .table-last-row {
                border-bottom: 1px solid lightgrey;
            }


            .pay-type-row {
                line-height: 80px;
            }

            .pay-desc-row {
                height: 80px;
                border-top: 1px solid lightgrey;
                line-height: 80px;

                .pay-remark-row {
                    text-align: center;
                    border-left: 1px solid lightgrey;
                    padding-bottom: 20px;
                    height: 80px;
                    box-sizing: border-box;

                    .pay-remark-input {
                        position: relative;
                        top: -12px;
                        width: 90%;
                    }
                }
            }

        }

        .pay-operator-row {
            margin-top: 48px;
        }

    }

    .vertical-line {
        border-left: #108ee9 solid 4px;
        padding-left: 10px;
    }

    .header-bread {
        background-color: #fff;
        font-size: 20px;
        padding: 20px 20px 20px 30px;
    }
}


</style>
